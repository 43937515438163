import { graphql } from 'gatsby';
export default from './boegen';


export const bowsPageQuery = graphql `
  query bowsPageENQuery {
    page: contentfulBowsPage(node_locale: {eq: "en"}) {
        ...BowsPageQueryFragment
    }
    bows: allContentfulBow(filter: {node_locale: {eq: "en"}}) {
      nodes {
        ...BowsQueryFragment
      }
    }
  }
`
